import React, { useState } from "react";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import Typography from "@mui/material/Typography";
import Grid from "@mui/material/Grid";
import logo from "../assets/images/logoOyc.jpeg"
import SearchResult from "./result";
import { Paper } from "@mui/material";
import apiInstance from "../services/apiInstance";
function SearchOycDNI() {
  const [dni, setDNI] = useState(""); // Estado para almacenar el DNI ingresado
  const [result, setResult] = useState(null); // Estado para almacenar la respuesta de la API

  const handleSearch = () => {
    // Realiza la solicitud a tu API cuando se hace clic en el botón de búsqueda
    apiInstance
      .post(`/OyCGetTarjeta/`,{dni:dni})
      .then((response) => {
        setResult(response.data); // Almacena la respuesta en el estado result
      })
      .catch((error) => {
        console.error("Error al buscar:", error);
        setResult(null); // Borra el resultado en caso de error
      });
  };

  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item xs={12}>
        <Paper
          elevation={3}
          style={{
            padding: "20px",
            textAlign: "center",
            maxWidth: "800px",
            margin: "0 auto",
          }}
        >
           <img src={logo} alt="Logo" style={{ maxWidth: "200px", margin: "0 auto", display: "block" }} /> {/* Añade el logo aquí */}
          <Typography variant="h4" style={{ marginBottom: "20px" }}>
            Consulta de Carnet Vial
          </Typography>
          <TextField
            label="Ingrese el DNI"
            variant="outlined"
            value={dni}
            onChange={(e) => setDNI(e.target.value)}
            style={{ width: "100%", marginBottom: "10px" }}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            style={{ width: "100%" }}
          >
            Buscar
          </Button>
          {result &&
             <SearchResult dniData={result[0]} />
          }
        </Paper>
      </Grid>
    </Grid>
  );
}

export default SearchOycDNI;

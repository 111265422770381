import { Routes, Route } from 'react-router-dom'
import SearchOycDNI from './consultas'

function App() {
  return (
    <Routes>
      <Route path="/" element={<SearchOycDNI />} />
    </Routes>
  )
}

export default App
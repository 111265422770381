import React from "react";
import Typography from "@mui/material/Typography";
import Paper from "@mui/material/Paper";
import SentimentSatisfiedAltIcon from '@mui/icons-material/SentimentSatisfiedAlt';
import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
function SearchResult({ dniData }) {
  const resultStyle = {
    padding: "20px",
    textAlign: "center",
    maxWidth: "400px",
    margin: "0 auto",
    marginTop: "20px",
  };

  const happyIconStyle = {
    color: "green",
    fontSize: "200px",
  };

  const sadIconStyle = {
    color: "red",
    fontSize: "200px",
  };

  if (!dniData) {
    return (
      <Paper elevation={3} style={resultStyle}>
        <SentimentVeryDissatisfiedIcon style={sadIconStyle} />
        <Typography variant="h6">No se encontraron resultados.</Typography>
      </Paper>
    );
  }

  const {
    dni,
    nombres,
    apellidos,
    fecha_inicio,
    fecha_fin,
    turno,
    empresa,
    distrito,
  } = dniData;

  return (
    <Paper elevation={3} style={resultStyle}>
      <SentimentSatisfiedAltIcon style={happyIconStyle} />
      <Typography variant="h6">
        Resultado para el Documento {dni}
      </Typography>
      
      {nombres && apellidos && (
        <Typography variant="h6">
          {nombres} {apellidos}
        </Typography>
      
    
      )}
    
    
      <Typography variant="body2">
        - Cuenta con carnet de educación vial Vigente hasta: {fecha_fin}
      </Typography>
    
      <Typography variant="body2">
        - Conductor de la {empresa}
      </Typography>
      <Typography variant="h6">
        - Distrito: {distrito}
      </Typography>
    </Paper>
  );
}

export default SearchResult;
